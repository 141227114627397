// @ts-nocheck
/* eslint-enable */
import React, { useState } from 'react';
import LayerIcon from 'images/icons/HP_Icon_Layers.svg';
import MapOptionsModal from '../MapOptionsModal';
import TransitOverlayOptions from '../TransitOverlayOptions/component';
import MapTypeOptions from '../MapTypeOptions';
import { useDispatch } from 'react-redux';
import AppActions from 'app/shared/flux/actions/AppActions';
import { MapOptionsButtonStyled, MapOptionsResetButtonStyled, MapOptionsResetButtonContainerStyled } from './styles';
import { yieldCallback } from '@zillow/yield-callback';

const MapOptionsButton = ({ map }) => {
    const dispatch = useDispatch();
    const [showOptionsModal, setShowOptionsModal] = useState(false);

    const showModal = () => yieldCallback(setShowOptionsModal(true));
    const closeModal = () => yieldCallback(setShowOptionsModal(false));
    const resetMap = () => yieldCallback(dispatch(AppActions.resetMap()));

    return (
        <>
            <MapOptionsButtonStyled onClick={showModal} aria-label="Click to show Google Map overlay options">
                <img src={LayerIcon} alt="" loading="lazy" height="24px" width="24px" />
            </MapOptionsButtonStyled>
            {showOptionsModal && (
                <MapOptionsModal
                    dialogId="mapOptions"
                    ariaLabel="show map options"
                    handleClickAway={closeModal}
                    onClose={closeModal}
                >
                    <MapOptionsResetButtonContainerStyled>
                        <MapOptionsResetButtonStyled onClick={resetMap}>Reset</MapOptionsResetButtonStyled>
                    </MapOptionsResetButtonContainerStyled>
                    <MapTypeOptions map={map} />
                    <TransitOverlayOptions />
                </MapOptionsModal>
            )}
        </>
    );
};

export default MapOptionsButton;
