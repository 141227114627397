// @ts-nocheck
/* eslint-enable */


const logger = getLogger('modules/map');

const controller = {
    createMarker(markerDetails = {}) {
        let { icon, lat, lon, map, onClick, zIndex } = markerDetails;
        let marker;
        let clickable = true;

        // If no map object, nothing is gonna happen.
        if (!map) {
            return false;
        }

        // Ensure we have valid info, if not, get out of here.
        if (!lat || !lon) {
            logger.warn('Do not have geo info for listing, do not add marker');
            return false;
        }

        if (!onClick) {
            clickable = false;
        }

        // Initializes a new marker object and adds it to the map.
        marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(lat, lon),
            anchorPoint: new window.google.maps.Point(0, -30), // Might need to be props (e.g., for dotmap)
            clickable,
            map,
            icon,
            zIndex
        });

        return marker;
    },

    createInfoWindow(marker, content) {
        if (!marker) {
            return;
        } else if (!marker.icon) {
            return marker;
        }

        let infoWindowOffSet;
        const height = marker.icon.scaledSize.height;

        if (!content) {
            // If no content found, we still setup
            // infoWindow object to handle / ignore
            // certain interactions without throwing
            // an error.
            // Methods: https://developers.google.com/maps/documentation/javascript/reference/info-window#InfoWindow
            marker.infoWindow = {
                close: () => { },
                getContent: () => { },
                getPosition: () => { },
                getZIndex: () => { },
                open: () => { },
                setContent: () => { },
                setOptions: () => { },
                setPosition: () => { },
                setZIndex: () => { }
            };

            return marker;
        }

        // Offset infoWindow height depending on size of icon.
        // Otherwise, infoWindow will potentially overlap icon
        if (height === 37) {
            // Building Icon
            infoWindowOffSet = 9;
        } else if (height === 32) {
            // Condo Icon
            infoWindowOffSet = 11;
        } else {
            // House icon
            infoWindowOffSet = 15;
        }

        marker.infoWindow = new window.google.maps.InfoWindow({
            content,
            disableAutoPan: true,
            pixelOffset: new window.google.maps.Size(0, infoWindowOffSet),
            position: marker.getPosition()
        });

        return marker;
    },

    handleShowInfoWindow(marker, bool) {
        if (!marker.infoWindow || (!marker.infoWindow && !bool)) {
            return;
        }

        // Prevent redrawing open InfoWindow (causes blinking) if already open.
        if (bool && marker.infoWindow.isOpen) {
            return;
        }

        if (bool) {
            // Why 999? Because a clicked infoWindow is ~600
            // and we want a highlighted window to appear above.
            marker.infoWindow.setZIndex(999);
            marker.infoWindow.open(window.map, marker);
            marker.infoWindow.isOpen = true;
        } else if (marker.infoWindow.isOpen) {
            marker.infoWindow.close();
            marker.infoWindow.isOpen = false;
        }
    },

    handleMarkerMouseOut({ marker = {}, zIndex }) {
        if (marker.infoWindow) {
            controller.handleShowInfoWindow(marker, false);
            marker.setOptions({ zIndex });
        }
    },

    handleMarkerMouseOver(marker = {}) {
        marker.setOptions({ zIndex: 999 });
        controller.handleShowInfoWindow(marker, true);
    }
};

export default controller;
