import styled from 'styled-components';
import { variables } from 'app/shared/styles/_variables';
import { viewports } from 'app/shared/styles/_breakpoints';
import { noUserSelect } from 'app/shared/styles/_mixins';

export const MapWrapper = styled.section`
    ${noUserSelect};
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0px;
    left: 0px;
    width: ${variables['sidebar-width']};

    @media ${viewports['md-and-up']} {
        display: inline-block;
        padding-right: ${variables['sidebar-width-md-bigger-listing-cards']};
        top: 0;
    }

    @media ${viewports['xl-and-up-bigger-listing-cards']} {
        padding-right: ${variables['sidebar-width-xl-bigger-listing-cards']};
    }
`;

export const StaticMapContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const StaticMapFixed = styled.div`
    flex-shrink: 0;
    position: relative;
`;

export const StaticMapImage = styled.img`
    transform: scale(2);
    image-rendering: pixelated;
    align-self: center;
`;
