// @ts-nocheck
/* eslint-enable */
import React from 'react';
import PropTypes from 'prop-types';
import 'app/shared/modules/location/GeolocationButton.scss';
import IconLocation from 'images/icons/location.svg';
import IconError from 'images/icons/error.svg';
import { yieldCallback } from '@zillow/yield-callback';

class GeolocationButton extends React.Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        onAccessDenied: PropTypes.func,
        onError: PropTypes.func
    };

    static defaultProps = {
        onSuccess: () => { },
        onAccessDenied: () => { },
        onError: () => { }
    };

    constructor(props) {
        super(props);
        this.state = {
            icon: (
                <img
                    src={IconLocation}
                    className="GeolocationButton-icon"
                    alt=""
                    loading="lazy"
                    height="24px"
                    width="24px"
                />
            )
        };
    }

    handleGeolocation = yieldCallback(() => {
        this.showSpinner();

        const hasBrowserSupport = navigator.geolocation;
        if (hasBrowserSupport) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.showDefaultIcon();
                    this.props.onSuccess(position);
                },
                (err) => {
                    if (err.code === err.PERMISSION_DENIED) {
                        this.showErrorIcon();
                        this.props.onAccessDenied();
                    }
                }
            );
        } else {
            this.showDefaultIcon();
            this.props.onError();
        }
    });

    showDefaultIcon = () => {
        this.setState({
            icon: <img src={IconLocation} className="GeolocationButton-icon" alt="" />
        });
    };

    showSpinner = () => {
        this.setState({
            icon: <div className="GeolocationButton-spinner" />
        });
    };

    showErrorIcon = () => {
        this.setState({
            icon: <img src={IconError} className="GeolocationButton-icon" alt="" />
        });
    };

    render() {
        return (
            <button
                className="GoogleMap-layer-btn GoogleMap-layer-icon"
                onClick={this.handleGeolocation}
                aria-label="Click to have Google Maps geolocate you"
            >
                {this.state.icon}
            </button>
        );
    }
}

export default GeolocationButton;
