// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { yieldCallback } from '@zillow/yield-callback';

import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { analyticsEvent } from 'app/client/universal-analytics';
import Text from 'app/shared/core/Text';
import './style.scss';

class MapRemoveBorderButton extends React.Component {
    static propTypes = {
        mapType: PropTypes.string,
        removeBorder: PropTypes.func
    };

    static defaultProps = {
        mapType: null,
        removeBorder: () => { }
    };

    constructor(props) {
        super(props);

        this.handleButtonClick = this.handleButtonClick.bind(this);
    }

    handleYieldButtonClick = yieldCallback(() => {
        const { removeBorder, dispatch } = this.props;
        dispatch(analyticsEvent(gaEvents.REMOVE_BOUNDARY));
        removeBorder();
    });

    handleButtonClick() {
        this.handleYieldButtonClick();
    }

    render() {
        const { mapType } = this.props;

        return (
            <div className="MapRemoveBorderBtn-top-right">
                <div
                    className={cx({
                        'MapRemoveBorderBtn-boundary-button': true,
                        'MapRemoveBorderBtn-boundary-button-satellite': mapType === 'satellite'
                    })}
                    onClick={this.handleButtonClick}
                >
                    <Text size="tiny">Remove border</Text>
                </div>
            </div>
        );
    }
}

export default connect()(MapRemoveBorderButton);
