// @ts-nocheck
/* eslint-enable */
// App
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

// Misc / utils
import appUtils from 'app/shared/utils/appUtils';
import * as S from './styles';

const Dialog = ({
    ariaLabel,
    children,
    dialogId,
    onClose,
    OverlayComponent = S.BaseOverlay,
    DialogComponent = S.BaseDialog,
    FooterComponent = S.BaseFooter,
    ButtonComponent = S.BaseButton
}) => {
    const ref = useRef(null);
    const buttonRef = useRef(null);

    useEffect(() => {
        appUtils.lockVerticalScrolling({ isForPortal: true });
        if (buttonRef.current) {
            buttonRef.current.focus();
        }

        return () => {
            appUtils.unlockVerticalScrolling();
        };
    }, []);

    // Esc key will close filter modal
    const handleOnKeyDown = (e) => {
        return e.keyCode === 27 && onClose();
    };

    // Clicking away will close filter modal
    const handleClickAway = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            onClose();
        }
    };

    return ReactDOM.createPortal(
        <OverlayComponent
            role="dialog"
            aria-label={ariaLabel}
            aria-modal="true"
            tabIndex="-1"
            onKeyDown={handleOnKeyDown}
            onClick={handleClickAway}
        >
            <FocusLock>
                <DialogComponent id={dialogId} ref={ref}>
                    {children}
                    <FooterComponent>
                        <ButtonComponent onClick={onClose} aria-label="Close modal" ref={buttonRef}>
                            Done
                        </ButtonComponent>
                    </FooterComponent>
                </DialogComponent>
            </FocusLock>
        </OverlayComponent>,
        document.body
    );
};

export default Dialog;
