// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import assign from 'lodash/assign';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { arrowPointLeft, arrowPointRight } from 'app/shared/styles/_mixins';

// Lodash
import omit from 'lodash/omit';
import queryUtils from 'app/shared/utils/queryUtils';

// Components
import Text from 'app/shared/core/Text';

// Misc / Utils
import constants from 'app/shared/constants/ConstantsBundle';
import numberUtils from 'app/shared/utils/numberUtils';
import IconArrowGrey from 'images/icons/arrow-grey.svg';
import './style.scss';

const StyledArrowLeft = styled.img`
    ${arrowPointLeft}
`;

const StyledArrowRight = styled.img`
    ${arrowPointRight}
`;
class ListingsCount extends React.Component {
    static propTypes = {
        totalListings: PropTypes.number,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        totalListings: 0,
        isLoading: false
    };

    constructor(props) {
        super(props);

        this.handleNavClick = this.handleNavClick.bind(this);
        this.getPagingText = this.getPagingText.bind(this);
    }

    handleNavClick(page) {
        const { pathname, query, isLoading } = this.props;
        let newQuery = assign({}, query, { page });
        if (isLoading) {
            return;
        }

        if (page !== 1) {
            newQuery = assign({}, query, { page });
        } else {
            newQuery = omit(query, 'page');
        }

        let url = pathname + queryUtils.stringify(newQuery);
        window.router.transitionTo(url);
    }

    getPagingText() {
        const { page, totalListings } = this.props;
        const upperBound = page * constants.MAX_LISTINGS_PER_PAGE;
        const lowerBound = upperBound - constants.MAX_LISTINGS_PER_PAGE + 1;
        const totalListingsCompact = numberUtils.compact(totalListings);

        if (totalListings === 0 || totalListings === 1 || totalListings - lowerBound === 0) {
            return `Viewing ${totalListings} ${totalListings === 1 ? 'listing ' : 'listings'}`;
        }

        return `Viewing ${totalListingsCompact} listings`;
    }

    render() {
        const { page, isLoading, maxPage } = this.props;
        const listingCountDetails = this.getPagingText();

        return (
            <div className="MapListingsCount">
                <div
                    className={cx('MapListingsCount-prev-page', {
                        'MapListingsCount-arrow-disabled': page <= 1
                    })}
                    onClick={page > 1 ? () => this.handleNavClick(page - 1) : null}
                >
                    <StyledArrowLeft
                        src={IconArrowGrey}
                        className={cx('MapListingsCount-arrow', {
                            'MapListingsCount-arrow-disabled': page <= 1
                        })}
                        alt=""
                    />
                </div>
                <div className="MapListingsCount-listing-count">
                    <Text size="tiny">{isLoading ? 'Loading listings...' : listingCountDetails}</Text>
                </div>
                <div
                    className={cx('MapListingsCount-next-page', {
                        'MapListingsCount-arrow-disabled': page >= maxPage
                    })}
                    onClick={page < maxPage ? () => this.handleNavClick(page + 1) : null}
                >
                    <StyledArrowRight
                        src={IconArrowGrey}
                        className={cx('MapListingsCount-arrow', {
                            'MapListingsCount-arrow-disabled': page >= maxPage
                        })}
                        alt=""
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const query = queryUtils.parse(ownProps.location.search);
    const maxBuildings = state.listings.totalBuildings;

    return {
        query,
        pathname: ownProps.location.pathname,
        maxPage: Math.max(Math.ceil(maxBuildings / constants.MAX_LISTINGS_PER_PAGE), 1),
        page: Number(query.page || 1),
        totalListings: state.listings.totalListings
    };
};

export default withRouter(connect(mapStateToProps)(ListingsCount));
