// @ts-nocheck
/* eslint-enable */
import React, { Fragment, useEffect, useState } from 'react';

import { iconForListing } from 'app/client/utils/map/gmapIcons';
import controller from './controller';
import MapMarker from 'app/shared/modules/map/MapMarker';

const ListingMarkers = (props) => {
    const {
        activeMarkerMaloneLotId,
        currentListing,
        onListingMarkerMouseOut,
        onListingMarkerMouseOver,
        onMarkerClick,
        map,
        isMobile,
        listings
    } = props;
    const [listingMapMarkers, updateListingMapMarkers] = useState([]);
    const [markerStore, updateMarkerStore] = useState({});

    // Store references to markers displayed on map for later quick lookup,
    // as opposed to re-rendering the entire array of markers in order to
    // find the one that we want to set as an active marker.
    const storeMarkerRef = (marker, listing) => {
        marker.listing = listing; // Needed for later reference in determining active icon...
        markerStore[listing.maloneLotIdEncoded] = marker;

        updateMarkerStore(markerStore);
    };

    // Generate array of listing markers displayed on map and only
    // update components when listings have actually changed.
    useEffect(() => {
        let listingMarkerComponentArray = [];

        for (let i = 0, total = listings.length; i < total; i++) {
            const listing = listings[i];

            listingMarkerComponentArray.push(
                <MapMarker
                    dataObj={listing}
                    icon={iconForListing(listing)}
                    infoWindowContent={!isMobile ? controller.listingInfoWindowContent(listing) : null}
                    key={`listing-${listing.maloneLotIdEncoded}`}
                    lat={listing.geo.lat}
                    lon={listing.geo.lon}
                    map={map}
                    onClick={onMarkerClick}
                    onMouseOut={onListingMarkerMouseOut}
                    onMouseOver={onListingMarkerMouseOver}
                    storeMarkerRef={storeMarkerRef}
                    zIndex={2}
                />
            );
        }

        updateListingMapMarkers(listingMarkerComponentArray);
        //eslint-disable-next-line
    }, [currentListing, listings]);

    // Handle toggle of active marker without causing entire listing array to rerender.
    useEffect(() => {
        const marker = activeMarkerMaloneLotId && markerStore[activeMarkerMaloneLotId];

        if (!marker || !map) {
            return;
        }

        let icon = iconForListing(marker.listing, true);
        marker.setIcon(icon);
        marker.infoWindow.open(map, marker);
        marker.infoWindow.setZIndex(999);
        marker.setOptions({ zIndex: 999 });

        return () => {
            icon = iconForListing(marker.listing, false);
            marker.infoWindow.close();
            marker.setIcon(icon);
            marker.setOptions({ zIndex: 998 });
        };

        //eslint-disable-next-line
    }, [activeMarkerMaloneLotId]);

    return <Fragment>{listingMapMarkers}</Fragment>;
};

export default ListingMarkers;
