// @ts-nocheck
/* eslint-enable */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import { FilterActions_handleFilterChange } from 'app/shared/flux/actions/FilterActions';
import AppActions from 'app/shared/flux/actions/AppActions';
import RouteActions from 'app/shared/flux/actions/RouteActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import IconHouseOutlined from 'images/icons/house-outlined.svg';
import numberUtils from 'app/shared/utils/numberUtils';
import PropTypes from 'prop-types';
import './style.scss';
import Text from 'app/shared/core/Text';

const StyledSpan = styled.span`
    color: ${colors['$hpx-blue-600']};
`;
const StyledDarkSpan = styled.span`
    color: ${colors['$hpx-grey-600']};
`;
const StyledImg = styled.img`
    color: ${colors['$hpx-grey-500']};
`;
const StyledAlertSpan = styled.span`
    color: ${colors['$hpx-red-400']};
`;
class NoResultsMapBanner extends React.Component {
    static propTypes = {
        showBorder: PropTypes.bool,
        numDefaultFilterListings: PropTypes.number,
        onZoomOut: PropTypes.function
    };

    constructor(props) {
        super(props);
    }

    handleClearFilters = () => {
        const { dispatch } = this.props;
        dispatch(FilterActions_handleFilterChange()).then(() => {
            dispatch(
                AppActions.toggleNotificationMessage({
                    content: 'Your filters have been reset.'
                })
            );
        });
    };

    handleRemoveBorder = () => {
        const { dispatch } = this.props;
        let mapData = gmapUtils.getMapData(window.map);
        mapData.border = false;
        dispatch(RouteActions.updateUrlWithMapData(mapData));
        dispatch(UserSearchActions.setCurrentSearch({ mapData }));
    };

    render() {
        const { area, numDefaultFilterListings, showBorder, onZoomOut } = this.props;

        if (numDefaultFilterListings === 0) {
            return (
                <div className="NoResultsMapBanner">
                    <div className="NoResultsMapBanner-icon-wrapper">
                        <StyledImg src={IconHouseOutlined} width={32} height={32} alt="" />
                    </div>
                    <div className="NoResultsMapBanner-text">
                        <StyledAlertSpan>0 properties in {area.name}.</StyledAlertSpan>
                        <br />
                        <Text>
                            <StyledDarkSpan>Expand your search by moving the map.</StyledDarkSpan>
                            <br />
                        </Text>
                        <Text className="NoResultsMapBanner-links">
                            <span onClick={onZoomOut} className="NoResultsMapBanner-link">
                                Zoom out
                            </span>
                        </Text>
                    </div>
                </div>
            );
        }

        return (
            <div className="NoResultsMapBanner">
                <div className="NoResultsMapBanner-icon-wrapper">
                    <StyledImg src={IconHouseOutlined} width={32} height={32} alt="" />
                </div>
                <div className="NoResultsMapBanner-text">
                    <StyledAlertSpan>0 matches.</StyledAlertSpan>
                    <br />
                    <Text>
                        <StyledSpan>
                            {numberUtils.prettyPrintNumber(numDefaultFilterListings)}
                        </StyledSpan>
                        <StyledDarkSpan> properties are excluded.</StyledDarkSpan>
                        <br />
                    </Text>
                    <Text className="NoResultsMapBanner-links">
                        <span onClick={this.handleClearFilters} className="NoResultsMapBanner-link">
                            Clear filters
                        </span>
                        {showBorder && (
                            <span onClick={this.handleRemoveBorder} className="NoResultsMapBanner-secondary-link">
                                Remove border
                            </span>
                        )}
                    </Text>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        area: state.area.area
    };
};

export default withRouter(connect(mapStateToProps)(NoResultsMapBanner));
