// @ts-nocheck
/* eslint-enable */
// App
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';

// Commponents
import RadioButton from 'app/shared/modules/MobileSRP/__BaseComponents__/RadioButton';

// Misc / utils
import { GeneralFilterSection } from 'app/shared/modules/MobileSRP/Header/FilterBar/styles';
import mapConstants from 'app/shared/constants/MapConstants';
import AppActions from 'app/shared/flux/actions/AppActions';

const MapTypeOptions = () => {
    // Redux
    const mapType = useSelector((state) => state.app.mapType);
    const dispatch = useDispatch();
    const updateMapType = (newMapType) => dispatch(AppActions.changeMapType(newMapType));

    const yieldUpdateMapType = useYieldCallback((type) => {
        updateMapType(type);
    });

    const handleUpdateMapType = (e) => {
        const mapType = e.target.value;
        yieldUpdateMapType(mapType);
    };

    return (
        <GeneralFilterSection>
            <fieldset id="map-type-options">
                <legend>Transit Options</legend>
                <RadioButton
                    id="map-type-option-default"
                    name="map-type-options"
                    ariaLabel="Default map type"
                    isChecked={mapType === mapConstants.DEFAULT}
                    value={mapConstants.DEFAULT}
                    onChange={handleUpdateMapType}
                >
                    Default
                </RadioButton>
                <RadioButton
                    id="map-type-option-satellite"
                    name="map-type-options"
                    ariaLabel="satellite map type"
                    value="satellite"
                    isChecked={mapType === mapConstants.SATELLITE}
                    onChange={handleUpdateMapType}
                >
                    Satellite
                </RadioButton>
            </fieldset>
        </GeneralFilterSection>
    );
};

export default MapTypeOptions;
