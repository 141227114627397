// @ts-nocheck
/* eslint-enable */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import IconLoad from 'images/icons/load.inline.svg';
import './style.scss';

class Spinner extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        message: PropTypes.string
    };

    constructor(props) {
        super(props);
    }

    render() {
        let classNameFromProps = this.props.className;
        let message;

        if (this.props.message) {
            message = <div className="Spinner-message">{this.props.message}</div>;
        }

        return (
            <div className={cx('Spinner', classNameFromProps)}>
                <div
                    className={cx({
                        'Spinner-default-load-wrapper': !classNameFromProps,
                        'Spinner-absolute-overlay-load-wrapper': classNameFromProps === 'Spinner-absolute-overlay'
                    })}
                >
                    <IconLoad className="Spinner-load-svg" />
                    {message}
                </div>
            </div>
        );
    }
}

export default Spinner;
