import { viewports } from "app/shared/styles/_breakpoints";
import styled from "styled-components";
import { BaseDialog } from "../../Dialog/styles";

const mobile = `
    width: calc(100% - 16px);
    bottom: 40px;
    left: 8px;
`;

const mdAndUp = `
    width: 250px;
    bottom: 175px;
    left: 60px;
`;

export const Dialog = styled(BaseDialog)`
    @media ${viewports['md-and-up']} {
        ${mdAndUp}
    }
    ${mobile}
`;

